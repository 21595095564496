export default {
    STORE_KEY: 'a56z0fzrNpl^2',

    //Prod
    HOST_URL: 'http://fortuna.rukurort.ru/',
    API_URL: 'http://fortuna.rukurort.ru/server/public/api/v1',
    PUBLIC_URL: 'http://fortuna.rukurort.ru/server/public/uploads/',
    //STORAGE_URL: '',
   
    getHeaders: function () {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }
  };